const quotedDirectDebitPaymentPath = [
    {
        id: 'DirectDebitPage',
        title: { id: 'quoteandbind.wizard.step.Direct Debit', defaultMessage: 'Direct Debit' },
        path: '/direct-debit',
        component: 'DirectDebitPage',
        stepProps: {
            template: 'NGHQuotedWizardPageTemplate',
            supplementDD: false
        }
    },
    {
        id: 'SuccessPage',
        title: { id: 'quoteandbind.wizard.step.Success', defaultMessage: 'Success' },
        path: '/success',
        component: 'SuccessPage',
        stepProps: {
            template: 'NGHQuotedWizardPageTemplate'
        }
    }
];

const quotedCreditCardPaymentPath = [
    {
        id: 'PaymentPage',
        title: { id: 'quoteandbind.wizard.step.Payment', defaultMessage: 'Payment' },
        path: '/payment',
        component: 'PaymentPage',
        stepProps: {
            template: 'NGHQuotedWizardPageTemplate'
        }
    },
    {
        id: 'PaymentStatusPage',
        title: { id: 'quoteandbind.wizard.step.PaymentStatus', defaultMessage: 'Payment Status' },
        path: '/payment-status',
        component: 'PaymentStatusPage',
        stepProps: {
            template: 'NGHQuotedWizardPageTemplate'
        }
    },
    {
        id: 'SuccessPage',
        title: { id: 'quoteandbind.wizard.step.Success', defaultMessage: 'Success' },
        path: '/success',
        component: 'SuccessPage',
        stepProps: {
            template: 'NGHQuotedWizardPageTemplate'
        }
    }
];

const quotedSupplimentaryPaymentPath = [
    {
        id: 'PaymentPage',
        title: { id: 'quoteandbind.wizard.step.Payment', defaultMessage: 'Payment' },
        path: '/payment',
        component: 'PaymentPage',
        stepProps: {
            template: 'NGHQuotedWizardPageTemplate'
        }
    },
    {
        id: 'PaymentStatusPage',
        title: { id: 'quoteandbind.wizard.step.PaymentStatus', defaultMessage: 'Payment Status' },
        path: '/payment-status',
        component: 'PaymentStatusPage',
        stepProps: {
            template: 'NGHQuotedWizardPageTemplate'
        }
    },
    {
        id: 'DirectDebitPage',
        title: { id: 'quoteandbind.wizard.step.Direct Debit', defaultMessage: 'Direct Debit' },
        path: '/direct-debit',
        component: 'DirectDebitPage',
        stepProps: {
            template: 'NGHQuotedWizardPageTemplate',
            supplementDD: true
        }
    },
    {
        id: 'SuccessPage',
        title: { id: 'quoteandbind.wizard.step.Success', defaultMessage: 'Success' },
        path: '/success',
        component: 'SuccessPage',
        stepProps: {
            template: 'NGHQuotedWizardPageTemplate'
        }
    }
];

const quotedUnsuccessfulPaymentPath = [
    {
        id: 'PaymentUnsuccessfulPage',
        title: { id: 'quoteandbind.wizard.step.Payment Unsuccessful', defaultMessage: 'Payment Unsuccessful' },
        path: '/payment-unsuccessful',
        component: 'PaymentUnsuccessfulPage',
        stepProps: {
            template: 'NGHQuotedWizardPageTemplate'
        }
    }
];

export {
    quotedDirectDebitPaymentPath,
    quotedCreditCardPaymentPath,
    quotedUnsuccessfulPaymentPath,
    quotedSupplimentaryPaymentPath
};
