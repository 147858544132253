import React, { useContext } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import messages from './PolicyDetailsSummary.messages';
import metadata from './PolicyDetailsSummary.metadata.json5';
import styles from './PolicyDetailsSummary.module.scss';
import useLobs from '../../../hooks/useLobs';

function PolicyDetailsSummary(props) { /* NOSONAR: pure declarative usage */
    const { submissionVM } = props;
    const translator = useContext(TranslatorContext);
    const {
        isCaravanLine,
        isTravelLine,
        isAnimalLine,
        isPersonalAccidentLine,
        isBuildingsCov,
        isContentsCov,
        isBespokeJourney
    } = useLobs(submissionVM);
    const coversIds = Object.freeze({
        LEX_PERSONAL_LEGAL_EXPENSES_COV: 'LEXPersonalLegalExpensesCov',
        CONTENTS_AWAY_COV: 'HOMContentsAwayCov',
        HOM_CONTENTS_AWAY_BICYCLE_EXTENSION: 'HOMContentsAwayCovPedalCycleExtension',
        HOM_CONTENTS_BICYCLE_EXTENSION: 'HOMContentsCovPedalCycleExtension',
        HOM_PERSONAL_CYBER_COV: 'HOMPersonalCyberCov',
    });
    const coverages = _.get(submissionVM.value, 'lobData.homeLine.lineCoverages.coverages');
    const isValuablesCov = coverages?.find((cov) => cov.publicID === 'HOMValuablesCov')?.selected;
    const isFineartAndCollectionCov = coverages?.find((cov) => cov.publicID === 'HOMFineArtCollectionsCov')?.selected;
    const isHomeEmergencyCov = coverages?.find((cov) => cov.publicID === 'HOMHomeEmergencyCov')?.selected;
    const LEVEL_OF_COVER_NAME = 'Level of Cover';
    const LEGAL_EXPENSES_EXTRA_KEY = 'Extra';
    const YES_CODE = 'Yes';
    const lexLineCoverages = _.get(submissionVM, 'lobData.value.lexLine.lineCoverages.coverages');
    const legalExpensesCover = lexLineCoverages?.find((cov) => cov.publicID === coversIds.LEX_PERSONAL_LEGAL_EXPENSES_COV);
    const isLegalExpensesCover = legalExpensesCover?.selected;
    const legalExpensesLevelOfCover = legalExpensesCover?.terms?.find((term) => term.name === LEVEL_OF_COVER_NAME)?.chosenTermValue;
    const iscontentsAwayCov = coverages?.find(((cov) => cov.publicID === coversIds.CONTENTS_AWAY_COV))?.selected;
    const contentsAwayCov = coverages?.find(((cov) => cov.publicID === coversIds.CONTENTS_AWAY_COV));
    const isBicycleExtensionCoverTerm = contentsAwayCov?.terms?.find((term) => term.publicID === coversIds.HOM_CONTENTS_AWAY_BICYCLE_EXTENSION)?.chosenTermValue === YES_CODE;
    const contentsCov = coverages?.find((cov) => cov.publicID === 'HOMContentsCov');
    const isBicycleExtensionCov = contentsCov?.terms?.find((term) => term.publicID === coversIds.HOM_CONTENTS_BICYCLE_EXTENSION)?.chosenTermValue === YES_CODE;
    const isPersonalCyberCov = coverages?.find((cov) => cov.publicID === 'HOMPersonalCyberCov')?.selected;
    const getCoverType = () => {
        const coverTypes = [];
        if (isBuildingsCov) {
            coverTypes.push(translator(messages.buildings));
        }
        if (isContentsCov) {
            coverTypes.push(translator(messages.contents));
        }
        if (isCaravanLine) {
            coverTypes.push(translator(messages.caravan));
        }
        if (isAnimalLine) {
            coverTypes.push(translator(messages.catdog));
        }
        if (isTravelLine) {
            coverTypes.push(translator(messages.travel));
        }
        if (isPersonalAccidentLine) {
            coverTypes.push(translator(messages.personalAccident));
        }
        if (isHomeEmergencyCov) {
            coverTypes.push(translator(messages.homeEmergencyCover));
        }
        if (isLegalExpensesCover) {
            coverTypes.push(legalExpensesLevelOfCover === LEGAL_EXPENSES_EXTRA_KEY
                ? translator(messages.personalLegalExpensesExtra): translator(messages.personalLegalExpenses));
        }
        if (iscontentsAwayCov) {
            coverTypes.push(translator(messages.contentsAway));
        }
        if (isBicycleExtensionCoverTerm) {
            coverTypes.push(translator(messages.bicycleExtensionCoverTitle));
        }

        if (isBespokeJourney) {
            if (isFineartAndCollectionCov) {
                coverTypes.push(translator(messages.fineartAndCollection));
            }
            if (isValuablesCov) {
                coverTypes.push(translator(messages.valuables));
            }
            if (isPersonalCyberCov) {
                coverTypes.push(translator(messages.personalCyberCover));
            }
            if (isBicycleExtensionCov) {
                coverTypes.push(translator(messages.bicycleExtensionCoverTitle));
            }
        }
        return coverTypes.join(', ');
    };

    const getAddress = () => {
        const address = [];
        const addressParts = ['addressLine1', 'addressLine2', 'addressLine3', 'city', 'county', 'postalCode'];
        addressParts.forEach((part) => {
            const partValue = _.get(submissionVM, `baseData.value.policyAddress.${part}`);
            if (partValue) {
                address.push(partValue);
            }
        });
        return address.join(', ');
    };

    const getPolicyDatesRange = () => {
        const { year, month, day } = _.get(submissionVM, 'baseData.value.periodStartDate');
        const startDate = moment(new Date(year, month, day)).format('DD MMMM YYYY');
        const { year: endYear, month: endMonth, day: endDay } = _.get(submissionVM, 'baseData.value.periodEndDate');
        const endDate = moment(new Date(endYear, endMonth, endDay)).format('DD MMMM YYYY');
        return `${startDate} to ${endDate}`;
    };

    const getPolicyHolderName = () => {
        const accountHolder = _.get(submissionVM, 'baseData.value.accountHolder');
        if (accountHolder.prefix === 'notSpecified_NFUM') {
            return `${accountHolder.firstName} ${accountHolder.lastName}`;
        }
        return accountHolder.displayName;
    };

    const overrideProps = {
        '@field': {
            readOnly: true,
            className: 'riskObjectPropertyContainer',
            labelClassName: 'riskObjectPropertyLabel'
        },
        policyHolder: {
            value: getPolicyHolderName()
        },
        policyType: {
            value: isBespokeJourney
                ? translator(messages.bespokeInsurance)
                : translator(messages.homeAndLifestyleInsurance)
        },
        coverType: {
            value: getCoverType()
        },
        address: {
            value: getAddress()
        },
        policyPeriodDateRange: {
            value: getPolicyDatesRange()
        },
        paymentType: {
            value: _.get(submissionVM, 'baseData.value.paymentMethod_NFUM') === 'cash'
                ? translator(messages.creditOrDebitCard)
                : translator(messages.directDebit)
        },
        optedOutOfAutoRenewal: {
            value: _.get(submissionVM, 'baseData.value.automaticRenewalOoption_NFUM') ? translator(messages.yes) : translator(messages.no)
        },
        customerCareDocumentFormat: {
            value: _.get(submissionVM, 'baseData.value.accountHolder.alternateFormat_NFUM')
                ? translator({
                    id: `typekey.CustCareDocFormatType_NFUM.${_.get(submissionVM, 'baseData.value.accountHolder.customerCareDocumentFormate_NFUM')}`,
                    defaultMessage: _.get(submissionVM, 'baseData.value.accountHolder.customerCareDocumentFormate_NFUM')
                })
                : translator(messages.noPreference)
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
    };

    return (
        <ViewModelForm
            model={submissionVM}
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
            overrideProps={overrideProps}
        />
    );
}

export default PolicyDetailsSummary;
