import { useContext } from 'react';
import { WizardContext } from 'gw-portals-wizard-react';

function useGlobalPhoneLayer() {
    const wizardContext = useContext(WizardContext);
    const PREQUALIFICATIONS_PHONE_NUMBER = '0800 208 8832';
    const ONLINE_QB_PHONE_NUMBER = '0800 208 8833';
    const PRICE_PROVIDED_PHONE_NUMBER = '0800 208 8835';

    const PHONE_NUMBERS_CONFIG = [
        {
            portalPageId: 'prequalification',
            phoneNumber: PREQUALIFICATIONS_PHONE_NUMBER
        },
        {
            portalPageId: 'your-details',
            phoneNumber: ONLINE_QB_PHONE_NUMBER
        },
        {
            portalPageId: 'property-details',
            phoneNumber: ONLINE_QB_PHONE_NUMBER
        },
        {
            portalPageId: 'property-construction',
            phoneNumber: ONLINE_QB_PHONE_NUMBER
        },
        {
            portalPageId: 'valuables',
            phoneNumber: ONLINE_QB_PHONE_NUMBER
        },
        {
            portalPageId: 'quote',
            phoneNumber: PRICE_PROVIDED_PHONE_NUMBER
        },
        {
            portalPageId: 'policy-summary',
            phoneNumber: PRICE_PROVIDED_PHONE_NUMBER
        },
        {
            portalPageId: 'direct-debit',
            phoneNumber: PRICE_PROVIDED_PHONE_NUMBER
        },
        {
            portalPageId: 'payment',
            phoneNumber: PRICE_PROVIDED_PHONE_NUMBER
        },
        {
            portalPageId: 'payment-status',
            phoneNumber: PRICE_PROVIDED_PHONE_NUMBER
        },
        {
            portalPageId: 'success',
            phoneNumber: PRICE_PROVIDED_PHONE_NUMBER
        }
    ];

    function getPhoneNumber() {
        let currentStepId;
        if (wizardContext) {
            currentStepId = wizardContext.currentStep.path.replace('/', '');
        }
        if (!currentStepId) {
            return undefined;
        }
        return PHONE_NUMBERS_CONFIG
            .find((config) => config.portalPageId === currentStepId)?.phoneNumber;
    }

    return {
        getPhoneNumber
    };
}

export default useGlobalPhoneLayer;
