import React, { useContext, useEffect, useState } from 'react';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { useHistory } from 'react-router-dom';
import { AppContext, useCommonTagManager } from 'nfum-portals-utils-react';
import cmsComponents from '../../constants/CmsConstants';
import styles from './BridgeNeedHelpBox.module.scss';

const NEED_HELP_PAGE_ID = 'need-help';
const INFINITY_TRACK_PHONE_CLASSNAME = 'inifinityTrackPhoneNumber';
const actions = Object.freeze({
    OPENS_POPUP: 'opensPopup',
    URL_REDIRECT: 'urlRedirect',
    INTERNAL_URL_REDIRECT: 'urlInternalRedirect',
    PHONE_APP_REDIRECT: 'phoneAppRedirect'
});

const SvgImage = ({ image, imageAlt }) => (
    <svg width="50" height="50">
        <image alt={imageAlt} xlinkHref={image?.url ? `${appConfig.env.NFUM_CMS_URL}${image.url}` : ''} width="50" height="50" role="img" aria-label="phone icon" />
    </svg>
);

const NeedHelpContent = ({ needHelpBox, mode }) => (
    <div className={styles.container}>
        {mode !== 'mobile' ? (
            <div className={styles.callUs}>
                {needHelpBox?.image && (
                    <div className={styles.iconContainer}>
                        <div className={styles.phoneIcon}>
                            <SvgImage image={needHelpBox.image} imageAlt={needHelpBox.imageAlt} />
                        </div>
                    </div>
                )}
                <div className={styles.infoContainer}>
                    <p
                        className={styles.callUsInfo}
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: needHelpBox?.topTextHtml }}
                    />
                    <p
                        className={`${styles.phoneInfo} ${INFINITY_TRACK_PHONE_CLASSNAME} orig-${needHelpBox?.phoneNumber}`}
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: needHelpBox?.subTextHtml }}
                    />
                </div>
            </div>
        ) : (
            <div className={styles.callUsMobile}>
                <span
                    className={styles.callUsInfoMobile}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: needHelpBox?.topTextHtml }}
                />
                <span
                    className={`${styles.phoneInfoMobile} ${INFINITY_TRACK_PHONE_CLASSNAME} orig-${needHelpBox?.phoneNumber}`}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: needHelpBox?.subTextHtml }}
                />
            </div>
        )}
    </div>
);

const BridgeNeedHelpBox = ({
    mode,
    openOnlineSupport,
    infinityPhoneNumber,
    doTryToObservePhoneNumber
}) => {
    const { marketingInfo } = useContext(AppContext);
    const history = useHistory();
    const { pushLinkClickInfo, pushRelativeLinkClickInfo } = useCommonTagManager();

    const needHelpBox = marketingInfo && marketingInfo[NEED_HELP_PAGE_ID]?.pageBlocks
        ?.find((block) => block.contentType.includes(cmsComponents.NEED_HELP));

    if (needHelpBox) {
        doTryToObservePhoneNumber();
    }

    function replacePhoneNumberIfExists(text, phoneNumber, newPhoneNumber) {
        if (!text || !phoneNumber) return '';

        // Remove all non-digit characters from the phone number
        const cleanPhoneNumber = phoneNumber.replace(/\D/g, '');

        // Create a regular expression pattern that matches the phone number
        // with optional spaces or other non-digit characters between digits
        const phonePattern = new RegExp(cleanPhoneNumber.split('').join('\\s*\\D*'), 'g');

        // Replace the matched phone number
        return text.replace(phonePattern, newPhoneNumber).trim();
    }

    const getAriaLabelWithInfinityPhoneNumber = () => {
        const ariaLabel = needHelpBox?.ariaLabel;
        const phoneNumberToBeReplaced = needHelpBox?.phoneNumber;
        const phoneNumberToReplace = infinityPhoneNumber;

        return replacePhoneNumberIfExists(ariaLabel, phoneNumberToBeReplaced, phoneNumberToReplace);
    };

    const onNeedHelpButtonClicked = () => {
        switch (needHelpBox?.action) {
            case actions.OPENS_POPUP:
                openOnlineSupport();
                break;
            case actions.PHONE_APP_REDIRECT:
                window.location.href = `tel: ${infinityPhoneNumber}`;
                break;
            case actions.URL_REDIRECT:
                pushLinkClickInfo(needHelpBox?.ariaLabel, needHelpBox?.link);
                window.open(needHelpBox?.link, '_blank');
                break;
            case actions.INTERNAL_URL_REDIRECT:
                pushRelativeLinkClickInfo(needHelpBox?.ariaLabel, needHelpBox?.link);
                history.push(needHelpBox?.link);
                break;
            default:
                break;
        }
    };

    return (
        <button
            className={styles.buttonContainer}
            type="button"
            onClick={onNeedHelpButtonClicked}
            aria-label={getAriaLabelWithInfinityPhoneNumber()}
            role="link"
        >
            <NeedHelpContent needHelpBox={needHelpBox} mode={mode} />
        </button>
    );
};

export default BridgeNeedHelpBox;
