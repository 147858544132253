import { defineMessages } from 'react-intl';

export default defineMessages({
    AboutYourHome: {
        id: 'quoteandbind.ngh.views.your-details.aboutYourHome',
        defaultMessage: 'Your home',
    },
    YourCover: {
        id: 'quoteandbind.ngh.views.your-details.yourCover',
        defaultMessage: 'Your cover',
    },
    YourDetails: {
        id: 'quoteandbind.ngh.views.your-details.yourDetails',
        defaultMessage: 'Your details',
    },
    PropertyStatus: {
        id: 'quoteandbind.ngh.views.your-details.propertyStatus',
        defaultMessage: 'Which of the following best describes your home ownership status?',
    },
    EnterAddressManually: {
        id: 'quoteandbind.ngh.views.your-details.enterAddressManually',
        defaultMessage: 'Enter address manually',
    },
    SubmitButton: {
        id: 'quoteandbind.ngh.views.your-details.submitButton',
        defaultMessage: 'Submit',
    },
    CoverType: {
        id: 'quoteandbind.ngh.views.your-details.coverType',
        defaultMessage: 'What type of home insurance would you like a quote for?',
    },
    EffectiveDate: {
        id: 'quoteandbind.ngh.views.your-details.effectiveDate',
        defaultMessage: 'When would you like the cover/policy to start?',
    },
    Title: {
        id: 'quoteandbind.ngh.views.your-details.title',
        defaultMessage: 'Title',
    },
    Mr: {
        id: 'quoteandbind.ngh.views.your-details.mr',
        defaultMessage: 'Mr',
    },
    Mrs: {
        id: 'quoteandbind.ngh.views.your-details.mrs',
        defaultMessage: 'Mrs',
    },
    Miss: {
        id: 'quoteandbind.ngh.views.your-details.miss',
        defaultMessage: 'Miss',
    },
    Ms: {
        id: 'quoteandbind.ngh.views.your-details.ms',
        defaultMessage: 'Ms',
    },
    Other: {
        id: 'quoteandbind.ngh.views.your-details.other',
        defaultMessage: 'Other',
    },
    FirstName: {
        id: 'quoteandbind.ngh.views.your-details.firstName',
        defaultMessage: 'First name',
    },
    LastName: {
        id: 'quoteandbind.ngh.views.your-details.lastName',
        defaultMessage: 'Last name',
    },
    EmailAddress: {
        id: 'quoteandbind.ngh.views.your-details.emailAddress',
        defaultMessage: 'Email address',
    },
    PhoneNumber: {
        id: 'quoteandbind.ngh.views.your-details.phoneNumber',
        defaultMessage: 'Phone',
    },
    DateOfBirth: {
        id: 'quoteandbind.ngh.views.your-details.dateOfBirth',
        defaultMessage: 'Date of birth',
    },
    EffectiveDateTooltip: {
        id: 'quoteandbind.ngh.views.your-details.effectiveDateTooltip',
        defaultMessage: ' Your policy will start from 00:01 on the date you select. If you want your policy to start today, it will start from the time you complete your purchase.',
    },
    SampleToolTip: {
        id: 'quoteandbind.ngh.views.your-details.sampleTooltip',
        defaultMessage: 'This is an informational tooltip',
    },
    CoverageWarningText: {
        id: 'quoteandbind.ngh.views.your-details.coverageWarningText',
        defaultMessage: 'Buildings cover is not available when Property status is: Rented furnished or Rented unfurnished or Rented Repairing Lease',
    },
    PrivacyPolicyInformationLink: {
        id: 'quoteandbind.ngh.views.your-details.privacyPolicyInformationLink',
        defaultMessage: 'Privacy Policy',
    },
    dot: {
        id: 'quoteandbind.ngh.views.your-details.dot',
        defaultMessage: '.',
    },
    PersonalInformationText: {
        id: 'quoteandbind.ngh.views.your-details.personalInformationText',
        defaultMessage: 'To find out more about how we use your personal information, and your rights, please read our ',
    },
    ByClickingContinueButtonMessage: {
        id: 'quoteandbind.ngh.views.your-details.clickingContinueButtonMessage',
        defaultMessage: 'By ',
    },
    continueBoldText: {
        id: 'quoteandbind.ngh.views.your-details.continueBoldText',
        defaultMessage: 'continuing:',
    },
    declarationContentListLiOne: {
        id: 'quoteandbind.ngh.views.your-details.declarationContentListLiOne',
        defaultMessage: 'You confirm that you will take reasonable care to answer all questions throughout this application honestly and to the best of your knowledge.  If you don’t, your policy may be cancelled, additional terms or premium may be applied, or your claim may be rejected or reduced.',
    },
    declarationContentListLiTwo:{
        id: 'quoteandbind.ngh.views.your-details.declarationContentListLiTwo',
        defaultMessage: 'You confirm you are happy for us to contact you regarding your quote.'
    },
    PrimaryContactNumber: {
        id: 'quoteandbind.ngh.views.your-details.primaryContactNumber',
        defaultMessage: 'Primary contact number'
    },
    Declaration: {
        id: 'quoteandbind.ngh.views.your-details.declaration',
        defaultMessage: 'Declaration',
    },
    documentsTitle: {
        id: 'quoteandbind.ngh.views.your-details.documentsTitle',
        defaultMessage: 'Document format'
    },
    primaryContactNumber: {
        id: 'quoteandbind.ngh.views.your-details.primaryContactNumber',
        defaultMessage: 'Primary contact number'
    },
    other: {
        id: 'quoteandbind.ngh.views.your-details.notSpecified_NFUM',
        defaultMessage: 'Other'
    },
    emailTooltip: {
        id: 'quoteandbind.ngh.views.your-details.emailTooltip',
        defaultMessage: 'We will use this email address to send you information about your quote and policy. We will not use this email for marketing purposes without your consent.'
    },
    buildingInsurance: {
        id: 'quoteandbind.ngh.views.your-details.buildingInsurance',
        defaultMessage: 'Buildings Insurance'
    },
    buildinginsuranceTooltip: {
        id: 'quoteandbind.ngh.views.your-details.buildinginsuranceTooltip',
        defaultMessage: 'Buildings Insurance covers the structure of your home - the roof, walls, ceilings, floor, doors and windows - as well as any permanent fixtures and fittings, such as your kitchen or bathroom.'
    },
    contentsInsurance: {
        id: 'quoteandbind.ngh.views.your-details.contentsInsurance',
        defaultMessage: 'Contents Insurance'
    },
    contentsinsuranceTooltip: {
        id: 'quoteandbind.ngh.views.your-details.contentsinsuranceTooltip',
        defaultMessage: 'Contents insurance is for anything that is not part of the structure of your home, for example your belongings and items of furniture. It also covers curtains and carpets.'
    },
    continue: {
        id: 'quoteandbind.ngh.views.your-details.continue',
        defaultMessage: 'Continue'
    },
    complete: {
        id: 'quoteandbind.ngh.views.your-details.complete',
        defaultMessage: 'Complete'
    },
    prepopulationLabelText: {
        id: 'quoteandbind.ngh.components.referralMessage.prepopulationLabelText',
        defaultMessage: 'Some of your personal details have been pre-populated. Please ensure the details are accurate and contact us if they need to be updated.'
    },
    referralTitle: {
        id: 'quoteandbind.ngh.components.referralMessage.referralTitle',
        defaultMessage: 'We need you to get in touch'
    },
    referralDescription: {
        id: 'quoteandbind.ngh.components.referralMessage.referralDescription',
        defaultMessage: 'Some of your personal details may be inaccurate. Please contact us to update them.'
    },
    claimsHistory: {
        id: 'quoteandbind.ngh.views.your-details.claimsHistory',
        defaultMessage: 'Claims history'
    },
    tenantsInteriorTooltipText: {
        id: 'quoteandbind.ngh.views.your-details.tenantsInteriorTooltipText',
        defaultMessage: 'Tenants Fixtures Cover'
    },
    tenantsInteriorTooltipText1: {
        id: 'quoteandbind.ngh.views.your-details.tenantsInteriorTooltipText1',
        defaultMessage: 'Do you need cover for improvements you have made to your building for example, a bathroom you have fitted to your leased or rented property? Please contact us to discuss our Tenants Fixtures cover.'
    },
    coverTypeDescText: {
        id: 'quoteandbind.ngh.views.your-details.coverTypeDescText',
        defaultMessage: 'Are you responsible for insuring any part of the building you live in? Check the terms of your lease or rental agreement for details and contact us to discuss this type of cover.'
    }
});
