import { defineMessages } from 'react-intl';

export default defineMessages({
    sessionTimeout: {
        id: 'nfumInactiveModal.sessionTimeout',
        defaultMessage: 'Do you still need a quote?'
    },
    inactiveModalInfo: {
        id: 'nfumInactiveModal.inactiveModalInfo',
        defaultMessage: 'We’ve noticed that you haven’t entered any information for a while. Do you want to continue?'
    },
    continueSessionButton: {
        id: 'nfumInactiveModal.continueSessionButton',
        defaultMessage: 'Yes, continue'
    }
});
