import React, { useCallback, useContext, useEffect } from 'react';
import { TranslatorContext } from '@jutro/locale';
import { useCommonTagManager } from 'nfum-portals-utils-react';
import { wizardProps } from 'gw-portals-wizard-react';
import { ModalNext, useModal } from '@jutro/components';
import { ViewModelForm } from 'gw-portals-viewmodel-react';

import metadata from './OnlineSupport.metadata.json5';
import styles from './OnlineSupport.module.scss';
import messages from './OnlineSupport.messages';

function ToolTipModal(props) {
    const {
        isOpen, onResolve, uiProps, stylesList, infinityPhoneNumber
    } = props;
    const translator = useContext(TranslatorContext);
    const {
        pushLinkClickInfo
    } = useCommonTagManager();

    const arrangeCallBack = useCallback(() => {
        const url = 'https://www.nfumutual.co.uk/contact-us/call-back/';
        pushLinkClickInfo(translator(messages.ArrangeaCallBack), url);
        window.open(url, '_blank');
    }, [pushLinkClickInfo, translator]);

    const sendEmail = useCallback(() => {
        const url = 'https://www.nfumutual.co.uk/contact-us/general-enquiry/local-office-enquiry/';
        pushLinkClickInfo(translator(messages.SendEmail), url);
        window.open(url, '_blank');
    }, [pushLinkClickInfo, translator]);

    const onRedirectToFAQ = useCallback((linkText) => {
        const url = 'https://www.nfumutual.co.uk/insurance/home-insurance/combined-buildings-and-contents/#are_my_belongings_covered_outside_the_home_or_when_i_go_on_holiday';
        pushLinkClickInfo(translator(linkText), url);
        window.open(url, '_blank');
    }, [pushLinkClickInfo, translator]);

    const overrideProps = {
        Link1: {
            onClick: () => onRedirectToFAQ(messages.WhatDoesBuildingInsuranceCover)
        },
        Link2: {
            onClick: () => onRedirectToFAQ(messages.WhatDoesContentsInsuranceCover)
        },
        Link3: {
            onClick: () => onRedirectToFAQ(messages.AreMyValuablesCoverOutsideOfmyHome)
        },
        faqLinkButton: {
            onClick: () => onRedirectToFAQ(messages.moreFaqs)
        },
        onlineSupportModalCallNumber: {
            content: infinityPhoneNumber
        }
    };

    const clickOnPhoneNumber = useCallback((event) => {
        event.preventDefault();
        window.location = `tel:${infinityPhoneNumber}`;
    }, [infinityPhoneNumber]);

    const resolvers = {
        resolveClassNameMap: stylesList,
        resolveCallbackMap: {
            closeModal: onResolve,
            arrangeCallBack: arrangeCallBack,
            sendEmail: sendEmail,
            clickOnPhoneNumber: clickOnPhoneNumber
        }
    };

    return (
        <ModalNext isOpen={isOpen} onRequestClose={onResolve} className={stylesList.popupArrow}>
            <ViewModelForm
                uiProps={uiProps}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                overrideProps={overrideProps}
            />
        </ModalNext>
    );
}

ToolTipModal.propTypes = wizardProps;

function OnlineSupport(props) {
    const {
        open,
        closeOnlineSupport,
        infinityPhoneNumber
    } = props;
    const { showModal } = useModal();
    const filterMetaData = useCallback(
        (filterId) => {
            return metadata.componentContent.content.filter((item) => item.id === filterId);
        },
        []
    );

    const onlineSupportModal = filterMetaData('onlineSupportModal');
    const onlineSupportButton = filterMetaData('onlineSupportButton');

    const handleOnClick = useCallback(async () => {
        await showModal(
            <ToolTipModal
                uiProps={onlineSupportModal}
                stylesList={styles}
                infinityPhoneNumber={infinityPhoneNumber}
            />
        );
        document.activeElement.focus();
    }, [onlineSupportModal, showModal, infinityPhoneNumber]);

    useEffect(() => {
        if (open === true) {
            handleOnClick();
            closeOnlineSupport();
        }
    }, [open, handleOnClick, closeOnlineSupport]);

    useEffect(() => {
        const onlineSupportIcon = document.querySelectorAll('.gw-help');
        onlineSupportIcon.forEach((icon) => {
            if (icon) {
                icon.setAttribute('title', 'Help Icon');
            }
        });
    }, []);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            handleOnClick: handleOnClick
        }
    };
    return (
        <ViewModelForm
            uiProps={onlineSupportButton}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

OnlineSupport.propTypes = wizardProps;
export default OnlineSupport;
