import React, { useContext } from 'react';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './ReferralMessageComponent.metadata.json5';
import styles from './ReferralMessageComponent.module.scss';

function ReferralMessage(props) {
    const {
        title,
        description,
        paragraph1,
        paragraph2,
        header,
        isUnderlineTheme,
        producerDesc,
        producerTele,
        isBespoke,
        showAgencyDetails,
        phoneNumber
    } = props;
    const translator = useContext(TranslatorContext);

    const overrideProps = {
        title: {
            visible: title != null,
            content: title,
        },
        description: {
            visible: description != null,
            content: description
        },
        paragraph1: {
            content: paragraph1,
            visible: paragraph1 != null,
            className: paragraph1 ? 'paragraph' : 'paragraphWithoutMargin'
        },
        paragraph2: {
            content: paragraph2,
            visible: paragraph2 != null,
            className: paragraph1 ? 'paragraph' : 'paragraphWithoutMargin'
        },
        referralMessageContainer: {
            className: isUnderlineTheme ? 'underlineThemeReferralMessageContainer' : 'referralMessageContainer'
        },
        referralMessageTitle: {
            visible: header != null,
            content: translator(header)
        },
        referralContentMessage: {
            visible: description != null,
            content: translator(description)
        },
        referralMessage: {
            producerDesc: producerDesc,
            producerTele: producerTele,
            isBespoke: isBespoke,
            showAgencyDetails: showAgencyDetails,
            showCallBack: true,
            phoneNumber: phoneNumber
        },
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

export default ReferralMessage;
