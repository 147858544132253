import React, { useState, useContext, useCallback } from 'react';
import _ from 'lodash';
import {
    ViewModelForm,
} from 'gw-portals-viewmodel-react';
import { wizardProps } from 'gw-portals-wizard-react';
import {
    ModalNext,
} from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { useValidation } from 'gw-portals-validation-react';
import { useCurrency } from 'nfum-portals-utils-react';
import metadata from './HighValueBicyclesModal.metadata.json5';
import styles from './HighValueBicyclesModal.module.scss';
import messages from './HighValueBicyclesModal.messages';

function HighValueBicyclesModal(props) {
    const {
        isOpen,
        onResolve,
        initialFormData = {},
        unspecifiedPedalCyclesLimit
    } = props;
    const translator = useContext(TranslatorContext);
    const [form, setForm] = useState(initialFormData);
    const { isComponentValid, onValidate } = useValidation('ValuablesModal');
    const [isReplacementCostFormatValid, setIsReplacementCostFormatValid] = useState(true);
    const currencyFormatter = useCurrency();
    const TEXT_FIELD_MAX_LENGTH = 255;
    const NUMERIC_FIELD_MAX_LENGTH = 16;
    const DIGITS_REGEXP = '^[0-9,.]+$|^$';

    const writeValue = useCallback((value, path) => {
        setForm({ ...form, [path]: value });
    }, [form]);

    const handleClose = (data) => () => {
        document.activeElement.focus();
        onResolve(data);
    };

    const isValuableBelowLimit = () => {
        const replacementCost = _.get(form, 'replacementCost');
        return replacementCost <= unspecifiedPedalCyclesLimit;
    };

    const getValuablesReplCostValidationMessages = () => {
        let validationMessages = [];
        if (isValuableBelowLimit()) {
            validationMessages = [
                ...validationMessages,
                translator(messages.unspecifiedPedalCyclesLimitValidationMessage, { limit: `${currencyFormatter.formatCurrency(unspecifiedPedalCyclesLimit)}` })
            ];
        }
        if (!isReplacementCostFormatValid) {
            validationMessages = [
                ...validationMessages,
                translator(messages.formatNotValid)
            ];
        }
        return validationMessages;
    };

    const isDigitsOnly = useCallback((value) => {
        const regex = new RegExp(DIGITS_REGEXP);
        return regex.test(value);
    }, []);

    const validateCharacters = useCallback((value, path) => {
        if (!isDigitsOnly(value)) return;
        writeValue(value, path);
    }, [writeValue, isDigitsOnly]);

    const validateFormat = useCallback((value) => {
        if (!currencyFormatter.isValidFormat(value)) {
            setIsReplacementCostFormatValid(false);
        } else {
            setIsReplacementCostFormatValid(true);
        }
    }, [currencyFormatter]);

    const toggleFieldRole = document.querySelectorAll('.jut__IconButton__iconButton');
    toggleFieldRole.forEach((role) => {
        if (role) {
            role.setAttribute('aria-label', 'close');
        }
    });

    const overrideProps = {
        saveAndAddButton: {
            visible: _.isEmpty(initialFormData),
            disabled: !isComponentValid
        },
        addItemButton: {
            disabled: !isComponentValid
        },
        highValueBicycleValue: {
            content: currencyFormatter.formatCurrency(unspecifiedPedalCyclesLimit)
        },
        bicycleWorthValue: {
            content: currencyFormatter.formatCurrency(unspecifiedPedalCyclesLimit)
        },
        bicyclesDescription: {
            maxLength: TEXT_FIELD_MAX_LENGTH,
            tooltip: {
                text: translator(messages.descriptionTooltip)
            }
        },
        bicyclesReplacementCost: {
            onValueChange: validateCharacters,
            onBlur: validateFormat,
            validationMessages: getValuablesReplCostValidationMessages(),
            maxLength: NUMERIC_FIELD_MAX_LENGTH,
            tooltip: {
                text: translator(messages.replacementCostTooltip)
            }
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onAddBicycle: handleClose({ type: 'ADD', data: form }),
            onSaveAndAdd: handleClose({ type: 'REOPEN', data: form }),
            onCancel: handleClose({ type: 'CANCEL' }),
        }
    };

    return (
        <ModalNext
            isOpen={isOpen}
            onRequestClose={() => handleClose({ type: 'CANCEL' })}
        >
            <ViewModelForm
                className={styles.modalWrapper}
                model={form}
                onValueChange={writeValue}
                onValidationChange={onValidate}
                uiProps={metadata.componentContent}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </ModalNext>
    );
}

HighValueBicyclesModal.propTypes = wizardProps;
export default HighValueBicyclesModal;
