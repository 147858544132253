import { defineMessages } from 'react-intl';

export default defineMessages({
    ConstructionMaterials: {
        id: 'quoteandbind.views.ngh.property-construction.constructionMaterials',
        defaultMessage: 'Construction materials',
    },
    InsuranceHistory: {
        id: 'quoteandbind.views.ngh.property-construction.insuranceHistory',
        defaultMessage: 'Insurance History',
    },
    yourContents: {
        id: 'quoteandbind.ngh.views.property-construction.yourContents',
        defaultMessage: 'Your contents',
    },
    estimateContentsCost: {
        id: 'quoteandbind.views.nghome.property-construction.estimateContentsCost',
        defaultMessage: 'How much would it cost to replace all of your contents?',
    },
    estimateContentsCostInfo: {
        id: 'quoteandbind.ngh.views.property-construction.estimateContentsCostInfo',
        defaultMessage: 'Using the information you’ve given us, we’ve worked out the cost of the cover you’d need to have your contents replaced new for old. If you’re happy with our recommendation then carry on. Otherwise tell us what your estimate would be. You can read our helpful guide to calculating your contents.',
    },
    EstimateMessageRicsUnavailable: {
        id: 'quoteandbind.views.nghome.property-construction.estimateMessageRicsUnavailable',
        defaultMessage: 'Estimate the cost of replacing all of your content',
    },
    estimateContentsCostInfoRicsUnavailable: {
        id: 'quoteandbind.ngh.views.property-construction.estimateContentsCostInfoRicsUnavailable',
        defaultMessage: 'Using the information you’ve given us, we’ve worked out the cost of the cover you’d need to have your contents replaced new for old. If you’re happy with our recommendation then carry on. Otherwise tell us what your estimate would be. You can read our helpful guide to calculating your contents.',
    },
    NumberOfYearsPlaceholder: {
        id: 'quoteandbind.views.nghome.property-construction.numberOfYearsPlaceholder',
        defaultMessage: 'Select a number',
    },
    enterValuePlaceholder: {
        id: 'quoteandbind.views.nghome.property-construction.enterValuePlaceholder',
        defaultMessage: 'Enter your estimate',
    },
    ValuableContents: {
        id: 'quoteandbind.views.nghome.property-construction.valuableContents',
        defaultMessage: 'Estimate the cost of replacing all your valuables',
    },
    ReplacementFigureInput: {
        id: 'quoteandbind.ngh.views.property-construction.replacementFigureInput',
        defaultMessage: 'We use the term valuables to cover jewellery, medals, coins and works of art as well as high value laptops and portable electronics. View a full list of what we consider a valuable',
    },
    PropertyRebuildCost: {
        id: 'quoteandbind.ngh.views.property-construction.propertyRebuildCost',
        defaultMessage: 'What is the rebuild cost of your home?',
    },
    PropertyRebuildCostInput: {
        id: 'quoteandbind.ngh.views.property-construction.propertyRebuildCostInput',
        defaultMessage: 'Based on the information you have provided we have estimated the rebuild cost of your property below. If this is not correct please provide a more accurate rebuilding cost',
    },
    PropertyRebuildCostRicsUnavailable: {
        id: 'quoteandbind.ngh.views.property-construction.propertyRebuildCostRicsUnavailable',
        defaultMessage: 'Estimate the cost of rebuilding your home',
    },
    PropertyRebuildCostInputRicsUnavailable: {
        id: 'quoteandbind.ngh.views.property-construction.propertyRebuildCostInputRicsUnavailable',
        defaultMessage: 'Remember, the rebuild cost of your home is the amount it costs in labour and materials to rebuild it from the ground up if it was destroyed by fire, flood or a storm, for example.',
    },
    IAgreeMessage: {
        id: 'quoteandbind.views.ngh.property-construction.IAgreeMessage',
        defaultMessage: 'I agree that the details provided are complete and accurate to the best of your knowledge at the point of submission. We reserve the right to verify all the information provided.',
    },
    YearOfContinuousInsurance: {
        id: 'quoteandbind.views.ngh.property-construction.yearOfContinuousInsurance',
        defaultMessage: 'How many years have you held continuous contents insurance without making a claim?',
    },
    PreviousInsurer: {
        id: 'quoteandbind.views.ngh.property-construction.previousInsurer',
        defaultMessage: 'Who was your previous insurer? (optional)',
    },
    YearOfBuildings: {
        id: 'quoteandbind.views.ngh.property-construction.yearOfBuildings',
        defaultMessage: 'How many years have you held continuous buildings insurance without making a claim?',
    },
    RebuildCostCalculationLink: {
        id: 'quoteandbind.views.ngh.property-construction.rebuildCostCalculationLink',
        defaultMessage: 'How has this been calculated?',
    },
    YearOfContents: {
        id: 'quoteandbind.views.ngh.property-construction.yearOfContents',
        defaultMessage: 'How many years have you held continuous contents insurance without making a claim?',
    },
    YearOfContentsAway: {
        id: 'quoteandbind.views.ngh.property-construction.yearOfContentsAway',
        defaultMessage: 'How many years of contents away from the home no claims discount do you hold with your previous insurer?',
    },
    InteriorDecorationCoverInput: {
        id: 'quoteandbind.ngh.views.property-construction.interiorDecorationCoverInput',
        defaultMessage: 'Do you want cover for your interior decorations?'
    },
    InteriorDecorationsCoverText: {
        id: 'quoteandbind.ngh.views.property-construction.interiorDecorationsCoverText',
        defaultMessage: 'By interior decorations we mean improvements you have made to the rented property you live in. Such as: Carpets, luxury wallpaper or paint'
    },
    CostOfInteriorDecorationsInput: {
        id: 'quoteandbind.ngh.views.property-construction.costOfInteriorDecorationsInput',
        defaultMessage: 'Estimate the cost of your interior decorations'
    },
    interiorDecorationsLimitExceeded: {
        id: 'quoteandbind.ngh.views.property-construction.interiorDecorationsLimitExceeded',
        defaultMessage: 'Does the total value of your interior decorations exceed £6,000?'
    },
    PaymentChoiceMessage: {
        id: 'quoteandbind.views.ngh.property-construction.paymentChoiceMessage',
        defaultMessage: 'You can choose to pay for your insurance premium monthly by Direct Debit or you can pay for the whole year up front by paying with a credit or debit card.',
    },
    PaymentNoAdditionalMessage: {
        id: 'quoteandbind.views.ngh.property-construction.paymentNoAdditionalMessage',
        defaultMessage: 'There are no additional charges or interest for paying monthly and unlike some insurers how you choose to pay won’t affect your price or premium.',
    },
    referralTitle: {
        id: 'quoteandbind.views.ngh.property-construction.referralTitle',
        defaultMessage: 'We think you would benefit from something more tailored',
    },
    referralDescription: {
        id: 'quoteandbind.views.ngh.property-construction.referralDescription',
        defaultMessage: 'Sorry, based on the information you have given us, we are unable to provide a quote online. Please contact us so we can support you with your Home Insurance quote.',
    },
    contentsReferralDescription: {
        id: 'quoteandbind.views.ngh.property-construction.contentsReferralDescription',
        defaultMessage: 'As a specialist insurer we are able to insure properties with a non-standard construction. However, based on the information you have given us, we won’t be able to do this online. So let’s talk to better understand your property and needs. It’s easy to get in touch.',
    },
    yourBuildings: {
        id: 'quoteandbind.views.ngh.property-construction.yourBuildings',
        defaultMessage: 'Your buildings'
    },
    estimateContentsCostInfo1: {
        id: 'quoteandbind.ngh.views.property-construction.estimateContentsCostInfo1',
        defaultMessage: 'Using the information you’ve given us, we’ve worked out the cost of the cover you’d need to have your contents replaced '
    },
    estimateContentsCostInfo2: {
        id: 'quoteandbind.ngh.views.property-construction.estimateContentsCostInfo2',
        defaultMessage: 'new for old'
    },
    estimateContentsCostInfo3: {
        id: 'quoteandbind.ngh.views.property-construction.estimateContentsCostInfo3',
        defaultMessage: '. If you’re happy this amount provides the correct cover then carry on. Otherwise tell us what your estimate would be. You can read our '
    },
    estimateContentsCostInfo4: {
        id: 'quoteandbind.ngh.views.property-construction.estimateContentsCostInfo4',
        defaultMessage: 'helpful guide'
    },
    estimateContentsCostInfo5: {
        id: 'quoteandbind.ngh.views.property-construction.estimateContentsCostInfo5',
        defaultMessage: ' to calculating your contents.'
    },
    contentsCostRicsUnavailableInfo1: {
        id: 'quoteandbind.ngh.views.property-construction.contentsCostRicsUnavailableInfo1',
        defaultMessage: 'We estimate the cost to replace all of your contents new for old to be '
    },
    contentsCostRicsUnavailableInfo2: {
        id: 'qquoteandbind.ngh.views.property-construction.contentsCostRicsUnavailableInfo2',
        defaultMessage: 'as new. '
    },
    contentsCostRicsUnavailableInfo3: {
        id: 'quoteandbind.ngh.views.property-construction.contentsCostRicsUnavailableInfo3',
        defaultMessage: '. If you do not think this is sufficient you may want to consider changing this amount. You can do so below.'
    },
    contentsCostRicsUnavailableInfo4: {
        id: 'quoteandbind.ngh.views.property-construction.contentsCostRicsUnavailableInfo4',
        defaultMessage: 'helpful guide'
    },
    youCanReadContent: {
        id: 'quoteandbind.ngh.views.property-construction.youCanReadContent',
        defaultMessage: 'You can read our '
    },
    contentsCostRicsUnavailableInfo5: {
        id: 'quoteandbind.ngh.views.property-construction.contentsCostRicsUnavailableInfo5',
        defaultMessage: 'If you reduce the contents sum insured and it doesn\'t fully reflect all the items in your home it may reduce our ability to pay a claim in full.'
    },
    contentsCostRicsUnavailableInfo6: {
        id: 'quoteandbind.ngh.views.property-construction.contentsCostRicsUnavailableInfo6',
        defaultMessage: 'You can read our helpful guide to calculating your contents.'
    },
    propertyRebuildCostInfo1: {
        id: 'quoteandbind.ngh.views.property-construction.propertyRebuildCostInfo1',
        defaultMessage: 'We estimate the average cost to rebuild your home to be '
    },
    propertyRebuildCostInfo3: {
        id: 'quoteandbind.ngh.views.property-construction.propertyRebuildCostInfo3',
        defaultMessage: '. If you have upgraded fixtures and fittings (for example high specification kitchen or bathroom), you may want to consider changing this amount. You can do so below.'
    },
    valuablesReplacementCostInfo1: {
        id: 'quoteandbind.ngh.views.property-construction.valuablesReplacementCostInfo1',
        defaultMessage: 'We use the term valuables to cover jewellery, medals, coins and works of art as well as high value laptops and portable electronics. '
    },
    valuablesReplacementCostInfo2: {
        id: 'quoteandbind.ngh.views.property-construction.valuablesReplacementCostInfo2',
        defaultMessage: 'View a full list of what we consider a valuable'
    },
    confirmingProvidedDetails: {
        id: 'quoteandbind.ngh.views.property-construction.confirmingProvidedDetails',
        defaultMessage: 'By ticking the box you are confirming that you are happy for NFU Mutual to verify all the information you have provided.'
    },
    yearOfContentsInfo4: {
        id: 'quoteandbind.ngh.views.property-construction.yearOfContentsInfo4',
        defaultMessage: 'without making a claim?'
    },
    yearOfContentsInfo3: {
        id: 'quoteandbind.ngh.views.property-construction.yearOfContentsInfo3',
        defaultMessage: ' insurance '
    },
    yearOfContentsInfo2: {
        id: 'quoteandbind.ngh.views.property-construction.yearOfContentsInfo2',
        defaultMessage: 'Contents'
    },
    yearOfContentsInfo1: {
        id: 'quoteandbind.ngh.views.property-construction.yearOfContentsInfo1',
        defaultMessage: 'How many years have you held continuous '
    },
    yearOfBuildingsInfo: {
        id: 'quoteandbind.ngh.views.property-construction.yearOfBuildingsInfo',
        defaultMessage: 'Buildings'
    },
    propertyRebuildCostInfo4: {
        id: 'quoteandbind.ngh.views.property-construction.propertyRebuildCostInfo4',
        defaultMessage: 'We ask that you estimate how much it would cost to rebuild your property from scratch, to the same specification and standard as it is now. Consider the cost of the building material used and the type of construction when making this estimate. A rebuild cost will have been supplied by a survey or in a mortgage offer.'
    },
    estimateContentsCostRicsUnavailableInfo4: {
        id: 'quoteandbind.ngh.views.property-construction.estimateContentsCostRicsUnavailableInfo4',
        defaultMessage: 'Carefully estimate the cost of replacing all your contents as new. You can read our '
    },
    estimateContentsCostRicsUnavailableInfo5: {
        id: 'quoteandbind.ngh.views.property-construction.estimateContentsCostRicsUnavailableInfo5',
        defaultMessage: ' to calculating your contents.'
    },

    propertyRebuildCostContainerInfo: {
        id: 'quoteandbind.ngh.views.property-construction.propertyRebuildCostContainerInfo',
        defaultMessage: 'If you reduce the rebuild amount and it doesn\'t accurately reflect the rebuild cost of your house, it may reduce our ability to pay a claim in full.'
    },
    propertyRebuildCostContainerInfo1: {
        id: 'quoteandbind.ngh.views.property-construction.propertyRebuildCostContainerInfo1',
        defaultMessage: 'If you provide an inaccurate rebuild cost of your house it may reduce our ability to pay a claim in full.'
    },
    helpfulGuide: {
        id: 'quoteandbind.ngh.views.property-construction.helpfulGuide',
        defaultMessage: 'helpful guide'
    }
});
