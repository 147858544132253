import { defineMessages } from 'react-intl';

export default defineMessages({
    yourOccupation: {
        id: 'quoteandbind.ngh.occupationLookup.occupation',
        defaultMessage: 'Occupation'
    },
    employmentStatus: {
        id: 'quoteandbind.ngh.components.occupationLookup.employmentStatus',
        defaultMessage: 'What is your employment status?'
    },
    employed: {
        id: 'quoteandbind.ngh.components.occupationLookup.employmentStatus.employed',
        defaultMessage: 'Employed'
    },
    retired: {
        id: 'quoteandbind.ngh.components.occupationLookup.employmentStatus.retired',
        defaultMessage: 'Retired'
    },
    unemployed: {
        id: 'quoteandbind.ngh.components.occupationLookup.employmentStatus.unemployed',
        defaultMessage: 'Unemployed'
    },
    housewife: {
        id: 'quoteandbind.ngh.components.occupationLookup.employmentStatus.housewife',
        defaultMessage: 'Housewife'
    },
    househusband: {
        id: 'quoteandbind.ngh.components.occupationLookup.employmentStatus.househusband',
        defaultMessage: 'Househusband'
    },
    employmentStatusTooltip: {
        id: 'quoteandbind.ngh.components.occupationLookup.employmentStatus.tooltip',
        defaultMessage: 'This is an informational tooltip'
    },
    isEmployInFarmingIndustry: {
        id: 'quoteandbind.ngh.components.occupationLookup.isEmployInFarmingIndustry',
        defaultMessage: 'Do you work in the farming industry?'
    },
    isEmployInFarmingIndustryTooltip: {
        id: 'quoteandbind.ngh.components.occupationLookup.isEmployInFarmingIndustry.tooltip',
        defaultMessage: 'This is an informational tooltip'
    },
    isEmployInFarmingIndustryYes: {
        id: 'quoteandbind.ngh.components.occupationLookup.isEmployInFarmingIndustry.yes',
        defaultMessage: 'Yes'
    },
    isEmployInFarmingIndustryNo: {
        id: 'quoteandbind.ngh.components.occupationLookup.isEmployInFarmingIndustry.no',
        defaultMessage: 'No'
    },
    isMemberOfFarmingUnion: {
        id: 'quoteandbind.ngh.components.occupationLookup.isMemberOfFarmingUnion',
        defaultMessage: 'Are you a member of an associated farming union?'
    },
    isMemberOfFarmingUnionYes: {
        id: 'quoteandbind.ngh.components.occupationLookup.isMemberOfFarmingUnion.yes',
        defaultMessage: 'Yes'
    },
    isMemberOfFarmingUnionNo: {
        id: 'quoteandbind.ngh.components.occupationLookup.isMemberOfFarmingUnion.no',
        defaultMessage: 'No'
    },
    farmingUnions: {
        id: 'quoteandbind.ngh.components.occupationLookup.farmingUnions',
        defaultMessage: 'Farming unions:'
    },
    farmingUnionsList: {
        id: 'quoteandbind.ngh.components.occupationLookup.farmingUnionsList',
        defaultMessage: 'National Farmers\' Union, National Farmers\' Union Scotland, Ulster Farmers\' Union or Manx National Farmers\' Union'
    },
    primaryOccupation: {
        id: 'quoteandbind.ngh.components.occupationLookup.primaryOccupation',
        defaultMessage: 'What is your primary occupation?'
    },
    primaryOccupationHelperText: {
        id: 'quoteandbind.ngh.components.occupationLookup.primaryOccupationHelperText',
        defaultMessage: 'Type in the first two letters to start a search, if you can’t find your exact role pick a similar one. If you have more than one occupation you can add more once you’ve selected your primary occupation. '
    },
    addAnother: {
        id: 'quoteandbind.ngh.components.occupationLookup.addAnother',
        defaultMessage: 'Add another'
    },
    unionMembershipIdLabel: {
        id: 'quoteandbind.ngh.components.occupationLookup.unionMembershipIdLabel',
        defaultMessage: 'What is your union membership ID?'
    },
    referralTitle: {
        id: 'quoteandbind.ngh.components.occupationLookup.referralTitle',
        defaultMessage: 'We need you to get in touch'
    },
    referralDescription: {
        id: 'quoteandbind.ngh.components.occupationLookup.referralDescription',
        defaultMessage: 'If you would like to add another occupation you will need to get in touch using the options below.'
    },
});
