import { defineMessages } from 'react-intl';

export default defineMessages({
    quoteNumber: {
        id: 'quoteandbind.pages.contactUsPage.quoteNumber',
        defaultMessage: 'Quote number'
    },
    Homepage: {
        id: 'quoteandbind.pages.contactUsPage.Homepage',
        defaultMessage: 'Homepage'
    },
    title: {
        id: 'quoteandbind.pages.contactUsPage.title',
        defaultMessage: 'We need you to get in touch'
    },
    description: {
        id: 'quoteandbind.pages.contactUsPage.description',
        defaultMessage: 'Based on the information you have provided to us, we need you to contact us so we can support you with your quote.​'
    },
    pageTitle: {
        id: 'quoteandbind.pages.contactUsPage.pageTitle',
        defaultMessage: 'Get in touch'
    }
});
