import { defineMessages } from 'react-intl';

export default defineMessages({
    descLengthValidationMessage: {
        id: 'quoteandbind.ngh.views.bicycleModal.descLengthValidationMessage',
        defaultMessage: 'You have reached maximum allowed field length'
    },
    highValueBicyclesModalTitle: {
        id: 'quoteandbind.ngh.views.highValueBicyclesModal.highValueBicyclesModalTitle',
        defaultMessage: 'High value bicycle'
    },
    highValueBicyclesModalTerms: {
        id: 'quoteandbind.ngh.views.highValueBicyclesModal.highValueBicyclesModalTerms',
        defaultMessage: 'High valued bicycles outside the home worth '
    },
    bicyclesDescription: {
        id: 'quoteandbind.ngh.views.highValueBicyclesModal.bicyclesDescription',
        defaultMessage: 'Description of the item'
    },
    tooltip: {
        id: 'quoteandbind.ngh.views.highValueBicyclesModal.tooltip',
        defaultMessage: 'This is an informational tooltip'
    },
    bicyclesReplacementCost: {
        id: 'quoteandbind.ngh.views.highValueBicyclesModal.bicyclesReplacementCost',
        defaultMessage: 'Replacement cost'
    },
    addItem: {
        id: 'quoteandbind.ngh.views.highValueBicyclesModal.addItem',
        defaultMessage: 'Add item'
    },
    cancel: {
        id: 'quoteandbind.ngh.views.highValueBicyclesModal.cancel',
        defaultMessage: 'Cancel'
    },
    saveAndAdd: {
        id: 'quoteandbind.ngh.views.highValueBicyclesModal.saveAndAdd',
        defaultMessage: 'Save and add another'
    },
    biyclesWorthDesription: {
        id: 'quoteandbind.ngh.views.highValueBicyclesModal.biyclesWorthDesription',
        defaultMessage: ' each. (Note: Bicycles worth up to '
    },
    includedInPolicy: {
        id: 'quoteandbind.ngh.views.highValueBicyclesModal.includedInPolicy',
        defaultMessage: ' are covered under Contents Away providing the limit you’ve chosen is sufficient.)'
    },
    moreThan: {
        id: 'quoteandbind.ngh.views.highValueBicyclesModal.moreThan',
        defaultMessage: 'more than '
    },
    maxLength: {
        id: 'quoteandbind.ngh.views.highValueBicyclesModal.maxLength',
        defaultMessage: 'You have reached maximum allowed field length'
    },
    formatNotValid: {
        id: 'quoteandbind.ngh.views.highValueBicyclesModal.formatNotValid',
        defaultMessage: 'Please enter only numerical values'
    },
    descriptionTooltip: {
        id: 'quoteandbind.ngh.views.highValueBicyclesModal.descriptionTooltip',
        defaultMessage: 'Please provide as much detail as possible here including make and model of the item you wish to insure. An accurate and specific physical description helps us deal with claims promptly.'
    },
    replacementCostTooltip: {
        id: 'quoteandbind.ngh.views.highValueBicyclesModal.replacementCostTooltip',
        defaultMessage: 'Remember this is the cost to replace the item new rather than its current value.'
    },
    unspecifiedPedalCyclesLimitValidationMessage: {
        id: 'quoteandbind.ngh.views.highValueBicyclesModal.unspecifiedPedalCyclesLimitValidationMessage',
        defaultMessage: 'Only items valued more than { limit } need to be declared'
    },
});
