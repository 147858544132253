import React, {
    useCallback,
    useEffect,
    useState,
    useContext
} from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import {
    useHistory
} from 'react-router-dom';
import { NfumLoader } from 'nfum-components-platform-react';
import { TranslatorContext } from '@jutro/locale';
import { useCommonTagManager } from 'nfum-portals-utils-react';
import { useAuthentication } from 'gw-digital-auth-react';
import { useDependencies } from 'gw-portals-dependency-react';
import styles from './ContactUsPage.module.scss';
import metadata from './ContactUsPage.metadata.json5';
import messages from './ContactUsPage.messages';

const ContactUsPage = () => {
    const history = useHistory();
    const translator = useContext(TranslatorContext);
    const {
        pushRelativeLinkClickInfo
    } = useCommonTagManager();
    const { authHeader } = useAuthentication();
    const [jobId, setJobId] = useState('');
    const [producerDesc, setProducerDesc] = useState(undefined);
    const [producerTele, setProducerTele] = useState(undefined);
    const [isBespoke, setIsBspoke] = useState(undefined);
    const [showAgencyDetails, setShowAgencyDetails] = useState(undefined);
    const { PolicyService } = useDependencies('PolicyService');
    const [isLoading, setLoadingState] = useState(false);

    useEffect(() => {
        const searchParams = new URLSearchParams(history.location.search);
        const jobID = searchParams.get('jobid');
        setJobId(jobID);
        if (jobID) {
            setLoadingState(true);
            PolicyService.retrieveProducerDetails([jobID, 'quote'], authHeader)
                .then((producerDetails) => {
                    setProducerDesc(producerDetails.producerCodeDescription);
                    setProducerTele(producerDetails.producerCodeTelephone);
                    setIsBspoke(producerDetails.isBespoke);
                    setShowAgencyDetails(producerDetails.showProducerDetails);
                }).finally(() => {
                    setLoadingState(false);
                });
        }
    }, [history, authHeader, PolicyService]);

    const onClose = useCallback(() => {
        const url = '/';
        pushRelativeLinkClickInfo(translator(messages.Homepage), url);
        history.push(url);
    }, [pushRelativeLinkClickInfo, translator, history]);

    if (isLoading) {
        return <NfumLoader loaded={!isLoading} />; /* NOSONAR: pure declarative usage */
    }
    const overrideProps = {
        referralMessage: {
            producerDesc: producerDesc,
            producerTele: producerTele,
            isBespoke: isBespoke,
            showAgencyDetails: showAgencyDetails,
            showCallBack: false,
            phoneNumber: '0800 208 8834'
        },
        closeButton: {
            onClick: onClose
        },
        quoteReference: {
            content: jobId ? `${translator(messages.quoteNumber)}: ${jobId}` : ''
        },
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
};

export default ContactUsPage;
