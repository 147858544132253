import { defineMessages } from 'react-intl';

export default defineMessages({
    noExtraCharge: {
        id: 'quoteandbind.ngh.views.payment.noExtraCharge',
        defaultMessage: 'We do not charge you any extra if you wish to pay in instalments'
    },
    supplementaryPaymentTitle: {
        id: 'quoteandbind.ngh.views.payment.supplementaryPaymentTitle',
        defaultMessage: 'Transaction 1 of 2: Initial payment'
    },
    supplementaryPaymentTopText: {
        id: 'quoteandbind.ngh.views.payment.supplementaryPaymentTopText',
        defaultMessage: 'You have chosen to pay by Direct Debit. As your policy starts within 10 days, which means a Direct Debit cannot be set up before your cover starts, we need to take an initial card payment. Once you have submitted your card details, we\'ll then ask you in the next step to provide the bank account details for the bank account you would like your Direct Debit to be taken from.'
    },
    creditCardPaymentTitle: {
        id: 'quoteandbind.ngh.views.payment.creditCardPaymentTitle',
        defaultMessage: 'Total to pay now'
    },
    switchToMonthlyPremium: {
        id: 'quoteandbind.ngh.views.payment.switchToMonthlyPremium',
        defaultMessage: 'Switch to monthly premium'
    },
    switchToYearlyPremium: {
        id: 'quoteandbind.ngh.views.payment.switchToYearlyPremium',
        defaultMessage: 'Switch to yearly premium'
    },
    editQuoteLink: {
        id: 'quoteandbind.ngh.views.payment.editQuoteLink',
        defaultMessage: 'Edit quote'
    },
    cancelTransaction: {
        id: 'quoteandbind.ngh.views.payment.cancelTransaction',
        defaultMessage: 'Cancel transaction'
    },
    paymentGatewayIframe: {
        id: 'quoteandbind.ngh.views.payment.paymentGatewayIframe',
        defaultMessage: 'Payment gateway iframe​'
    },
});
