import { defineMessages } from 'react-intl';

export default defineMessages({
    onlineSupportModalTitle: {
        id: 'quoteandbind.ngh.component.onlineSupport.onlineSupportModalTitle',
        defaultMessage: 'How can we help?'
    },
    WhatDoesBuildingInsuranceCover: {
        id: 'quoteandbind.ngh.component.onlineSupport.What does building insurance cover',
        defaultMessage: 'What does building insurance cover?'
    },
    WhatDoesContentsInsuranceCover: {
        id: 'quoteandbind.ngh.component.onlineSupport.What does contents insurance cover',
        defaultMessage: 'What does contents insurance cover?'
    },
    AreMyValuablesCoverOutsideOfmyHome: {
        id: 'quoteandbind.ngh.component.onlineSupport.Are my valuables cover outside of my home',
        defaultMessage: 'Are my valuables covered outside of my home?'
    },
    moreFaqs: {
        id: 'quoteandbind.ngh.component.onlineSupport.moreFAQs',
        defaultMessage: 'More FAQs'
    },
    Callus: {
        id: 'quoteandbind.ngh.component.onlineSupport.Call us',
        defaultMessage: 'Call us'
    },
    CallTimings: {
        id: 'quoteandbind.ngh.component.onlineSupport.CallTimings',
        defaultMessage: 'Mon to Fri:  8am to 8pm'
    },
    SaturdayCallTimings: {
        id: 'quoteandbind.ngh.component.onlineSupport.SaturdayCallTimings',
        defaultMessage: 'Saturday: 9am to 12:30pm'
    },
    SundayCallTimings: {
        id: 'quoteandbind.ngh.component.onlineSupport.SundayCallTimings',
        defaultMessage: 'Sunday:  Closed'
    },
    ArrangeaCallBack: {
        id: 'quoteandbind.ngh.component.onlineSupport.Arrange a call back',
        defaultMessage: 'Arrange a call back'
    },
    RequestaCallBack: {
        id: 'quoteandbind.ngh.component.onlineSupport.requestCallBack',
        defaultMessage: 'Request a call back'
    },
    convenientForYou: {
        id: 'quoteandbind.ngh.component.onlineSupport.likeToCallYou',
        defaultMessage: 'Would you like us to call you?'
    },
    ArrangeaCall: {
        id: 'quoteandbind.ngh.component.onlineSupport.requestaCall',
        defaultMessage: 'Request a call'
    },
    EmailUs: {
        id: 'quoteandbind.ngh.component.onlineSupport.Send us an enquiry',
        defaultMessage: 'Send us an enquiry'
    },
    EmailQuery: {
        id: 'quoteandbind.ngh.component.onlineSupport.onlineSupportModalEmailUsTerms',
        defaultMessage: 'You can send us a message with your query'
    },
    SendEmail: {
        id: 'quoteandbind.ngh.component.onlineSupport.Send an enquiry',
        defaultMessage: 'Send an enquiry'
    }
});
