const componentsVars = {
    // Inputs (packages/jutro-components/widgets/inputs/*)
    '--GW-FIELD-COMPONENT-HEIGHT': '2.8rem',
    '--GW-FIELD-COMPONENT-COLOR': 'black',
    '--GW-FIELD-COMPONENT-REQUIRED-SYMBOL-COLOR': 'red',
    '--GW-FIELD-COMPONENT-VALIDATION-ICON-COLOR': '#fff',
    '--GW-FIELD-COMPONENT-VALID-COLOR': '#1e9acf',

    '--GW-LOADER-Z-INDEX': 105
};

export default componentsVars;
