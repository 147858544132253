/* eslint-disable */
import React, { useState, useContext } from 'react';
import { Button } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import classNames from 'classnames';

import messages from './NfumFieldLabel.messages';
import styles from './NfumFieldLabel.module.scss';

function NfumFieldLabel(props) { /* NOSONAR: pure declarative usage */
    const translator = useContext(TranslatorContext);

    const {
        id,
        showRequired,
        required,
        schemaRequired,
        readOnly,
        disabled,
        label,
        tooltip,
        rightTooltip,
        noMargin,
        rightMargin,
        children: tooltipContentChildren,
        showOptional,
        className,
        isMaxWidth
    } = props;
    const [tooltipHidden, updateTooltipHidden] = useState(true);

    const requiredIndicator = showRequired &&
        (required || schemaRequired) &&
        !readOnly &&
        !disabled && (
            <span aria-hidden="true" className={styles.requiredSymbol}>
                *
            </span>
        );

    const createLabel = () => {
        const _translatedLabel = label && translator(label);
        return (
            _translatedLabel && (
                <span className={styles.primaryLabel}>{_.trim(_translatedLabel)}</span>
            )
        );
    }

    const translatedLabel = createLabel();

    const toggleTooltip = (event) => {
        if (event.type === 'click') {
            updateTooltipHidden(!tooltipHidden);
          }
    }

    const handleEnterKey = (event) => {
        if (event.key === 'Enter') {
          toggleTooltip();
        }
      };

    const renderTooltipIcon = () => {
        return (
            <button
                type="button"
                className={classNames(styles.tooltipIcon, {
                    tooltip: tooltipHidden,
                    tooltip_selected: !tooltipHidden,
                })}
                onClick={toggleTooltip}
                onKeyDown={handleEnterKey}
                tabIndex={0}
                role="tooltip"
                aria-labelledby={id}
            />
        );
    }

    const tooltipIcon = tooltip !== undefined && renderTooltipIcon();

    return (
        <div className={styles.nfumInputContainer}>
            <div className={classNames(styles.nfumFieldLabelContainer,
                className,
                { [styles.noMargin]: noMargin },
                { [styles.rightMargin]: rightMargin })}>
                <label id={id} className={styles.nfumFieldLabel}>
                    {requiredIndicator}
                    {translatedLabel}
                    {rightTooltip && <div className={styles.rightTooltip}></div>}
                    {tooltipIcon}
                    {showOptional ? ' (optional)' : ''}
                </label>
            </div>

            {!isMaxWidth &&
                <div role="tooltip" className={classNames(styles.tooltipContainer, { [styles.tooltipHidden]: tooltipHidden })} >
                    <div className={styles.tooltipText}>
                        {tooltip !== undefined && tooltip.text ? translator(tooltip.text) : tooltipContentChildren}
                    </div>
                    <Button
                        type="text"
                        icon="gw-close"
                        iconPosition="right"
                        onClick={toggleTooltip}
                        tabIndex="0"
                    >
                        {translator(messages.close)}
                    </Button>
                </div>
            }

            {isMaxWidth &&
                <div role="tooltip" className={classNames(styles.radioButtonTooltipContainer, { [styles.tooltipHidden]: tooltipHidden })} >
                    <div className={styles.tooltipText}>
                        {tooltip !== undefined && tooltip.text ? translator(tooltip.text) : tooltipContentChildren}
                    </div>
                    <Button
                        type="text"
                        icon="gw-close"
                        iconPosition="right"
                        onClick={toggleTooltip}
                        tabIndex="0"
                    >
                        {translator(messages.close)}
                    </Button>
                </div>
            }


        </div>
    );
}

export default NfumFieldLabel;
